import React from "react";
/** @jsx jsx */
import { jsx, Box, useThemeUI } from "theme-ui";
import { Parallax } from "../../components/animate";
import { CallToActionVague1, CallToActionVague2 } from "../../components/svg";
import {
  Flex,
  Text,
  Heading,
  FlexGrid,
  Container,
  AspectRatio,
  Button,
} from "../../components/core";
import { motion } from "framer-motion";
import ImageWithOverlay from "../../components/ImageWithOverlay";
import { InView } from "react-intersection-observer";
import Page from "../../components/Page";
import { BackgroundVague, Vague } from "../../components/svg";
import { graphql } from "gatsby";
import { alpha } from "@theme-ui/color";
import SEO from "../../components/SEO";
import { getImage } from "gatsby-plugin-image";
import { ReactMarkdown } from "../../components/ReactMarkdown";

const Sensibilisations = ({ data }) => {
  const { theme } = useThemeUI();
  const frontmatter = data.markdownRemark.frontmatter;
  return (
    <Page>
      <SEO
        title="Sensibilisations"
        description="Association marseillaise créée en 2017, Clean my Calanques milite pour la préservation de l'environnement et a pour ambition de sensibiliser l'ensemble de la population aux enjeux environnementaux."
      />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête sensibilisations"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
              sx={{ textAlign: "center" }}
            >
              <Heading as="h1" variant="h1" color="white">
                Sensibilisations
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Container sx={{ paddingTop: [8, null, 10] }}>
        <Heading as="h2" color="secondary" sx={{ marginBottom: 3 }}>
          {frontmatter.intro.title}
        </Heading>
        <Text>{frontmatter.intro.text}</Text>
        <Vague
          color="primary"
          sx={{
            marginTop: 6,
            marginBottom: 3,
          }}
        />
      </Container>
      <BackgroundVague
        width="100%"
        height={null}
        color="background3"
        sx={{
          transform: "scaleX(-1) rotate(180deg)",
          bottom: "-1px",
        }}
      />
      <Box
        as="section"
        sx={{
          backgroundColor: "background3",
          py: [8, null, 13],
        }}
      >
        <Container>
          <Heading
            as="h3"
            variant="h1"
            color="secondary"
            sx={{ marginBottom: [5, null, 8] }}
          >
            Écoles
          </Heading>
          <FlexGrid
            columns={[12, null, 6]}
            direction={["column", null, "row"]}
            sx={{
              marginBottom: 6,
            }}
            gutter={0}
          >
            <Box>
              <Heading
                as="h3"
                variant="h2"
                color="primary"
                sx={{ marginBottom: 3 }}
              >
                {frontmatter.ecoles.part1.title}
              </Heading>
              <Text as="div">
                <ReactMarkdown>{frontmatter.ecoles.part1.text}</ReactMarkdown>
              </Text>
            </Box>
            <Box
              sx={{
                paddingLeft: [0, null, 13],
                marginTop: [4, null, 0],
                textAlign: ["center", null, "initial"],
              }}
            >
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                  <ImageWithOverlay
                    alt="Image 1 ecoles"
                    ref={ref}
                    isAnimated={inView}
                    overlayColor="background3"
                    image={getImage(frontmatter.ecoles.part1.image)}
                    direction="right"
                  />
                )}
              </InView>
            </Box>
          </FlexGrid>
          <FlexGrid
            columns={[12, null, 6]}
            direction={["column-reverse", null, "row"]}
            gutter={0}
          >
            <Box
              sx={{
                paddingRight: [0, null, 13],
                marginTop: [4, null, 0],
                textAlign: ["center", null, "initial"],
              }}
            >
              <InView triggerOnce threshold={0.5}>
                {({ inView, ref }) => (
                  <ImageWithOverlay
                    alt="Image 2 ecoles"
                    ref={ref}
                    isAnimated={inView}
                    image={getImage(frontmatter.ecoles.part2.image)}
                    overlayColor="background3"
                  />
                )}
              </InView>
            </Box>
            <Box>
              <Heading
                as="h3"
                variant="h2"
                color="primary"
                sx={{ marginBottom: 3 }}
              >
                {frontmatter.ecoles.part2.title}
              </Heading>
              <Text as="div">
                <ReactMarkdown>{frontmatter.ecoles.part2.text}</ReactMarkdown>
              </Text>
            </Box>
          </FlexGrid>
        </Container>
      </Box>
      <Box sx={{ position: "relative", paddingBottom: [13, null, 18] }}>
        <Container
          variant="containerLg"
          sx={{
            zIndex: 1,
            position: "relative",
          }}
        >
          <CallToAction
            variant="secondary"
            title="Votre école est interessée ?"
            text="Parlons-nous et organisons une sensibilisation auprès des plus jeunes !"
            sx={{
              zIndex: 1,
            }}
          />
        </Container>
        <BackgroundVague
          width="100%"
          height={null}
          color="background3"
          sx={{
            position: "absolute",
            transform: "scaleX(-1)",
            top: 0,
          }}
        />
      </Box>
      <Container as="section" sx={{ paddingBottom: [8, null, 13] }}>
        <Heading
          as="h3"
          variant="h1"
          color="secondary"
          sx={{ marginBottom: [5, null, 8] }}
        >
          Entreprises
        </Heading>
        <FlexGrid
          columns={[12, null, 6]}
          direction={["column", null, "row"]}
          sx={{
            marginBottom: 6,
          }}
          gutter={0}
        >
          <Box>
            <Heading
              as="h3"
              variant="h2"
              color="primary"
              sx={{ marginBottom: 3 }}
            >
              {frontmatter.entreprises.part1.title}
            </Heading>
            <Text as="div">
              <ReactMarkdown>
                {frontmatter.entreprises.part1.text}
              </ReactMarkdown>
            </Text>
          </Box>
          <Box
            sx={{
              paddingLeft: [0, null, 13],
              marginTop: [4, null, 0],
              textAlign: ["center", null, "initial"],
            }}
          >
            <InView triggerOnce threshold={0.5}>
              {({ inView, ref }) => (
                <ImageWithOverlay
                  alt="Image 1 entreprises"
                  ref={ref}
                  isAnimated={inView}
                  image={getImage(frontmatter.entreprises.part1.image)}
                  direction="right"
                />
              )}
            </InView>
          </Box>
        </FlexGrid>
        <FlexGrid
          columns={[12, null, 6]}
          direction={["column-reverse", null, "row"]}
          gutter={0}
        >
          <Box
            sx={{
              paddingRight: [0, null, 13],
              marginTop: [4, null, 0],
              textAlign: ["center", null, "initial"],
            }}
          >
            <InView triggerOnce threshold={0.5}>
              {({ inView, ref }) => (
                <ImageWithOverlay
                  alt="Image 2 entreprises"
                  ref={ref}
                  isAnimated={inView}
                  image={getImage(frontmatter.entreprises.part2.image)}
                />
              )}
            </InView>
          </Box>
          <Box>
            <Heading
              as="h3"
              variant="h2"
              color="primary"
              sx={{ marginBottom: 3 }}
            >
              {frontmatter.entreprises.part2.title}
            </Heading>
            <Text as="div">
              <ReactMarkdown>
                {frontmatter.entreprises.part2.text}
              </ReactMarkdown>
            </Text>
          </Box>
        </FlexGrid>
      </Container>
      <Container variant="containerLg" sx={{ paddingBottom: [8, null, 13] }}>
        <CallToAction
          variant="primary"
          title="Votre entreprise est interessée ?"
          text="Parlons-nous et organisons un ramassage avec votre structure !"
        />
      </Container>
    </Page>
  );
};

export default Sensibilisations;

const CallToAction = ({ title, text, variant, sx, ...props }) => (
  <Box
    sx={{
      position: "relative",
      backgroundColor: variant === "primary" ? "#D5F1F2" : "#33BBBD",
      color: variant === "primary" ? "secondary" : "white",
      borderRadius: "16px",
      py: 8,
      px: [2, 8, null],
      textAlign: "center",
      overflow: "hidden",
      ...sx,
    }}
    {...props}
  >
    <CallToActionVague1
      width={["284px", "453px", null]}
      height={null}
      color={variant === "primary" ? "#33BBBD" : "#0C4B66"}
      sx={{ position: "absolute", top: 0, left: 0 }}
    />
    <CallToActionVague2
      width={["310px", "598px", null]}
      height={null}
      sx={{ position: "absolute", bottom: 0, right: 0 }}
    />
    <Box sx={{ position: "relative", zIndex: 1 }}>
      <Heading as="h1" sx={{ marginBottom: 4 }}>
        {title}
      </Heading>
      <Text>{text}</Text>
      <Button
        sx={{
          marginTop: 4,
          mx: "auto",
        }}
        to="/contact"
        backgroundColor={variant}
      >
        Nous contacter
      </Button>
    </Box>
  </Box>
);

export const query = graphql`
  query SensibilisationsQuery {
    markdownRemark(fields: { slug: { eq: "/sensibilisations/" } }) {
      id
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        intro {
          text
          title
        }
        ecoles {
          part1 {
            title
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 760
                )
              }
            }
          }
          part2 {
            title
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 760
                )
              }
            }
          }
        }
        entreprises {
          part1 {
            title
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 760
                )
              }
            }
          }
          part2 {
            title
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: CONSTRAINED
                  width: 760
                )
              }
            }
          }
        }
      }
    }
  }
`;
